[
    {
       "name":"Afghanistan",
       "id":"af",
       "code":93
    },
    {
       "name":"Albania",
       "id":"al",
       "code":355
    },
    {
       "name":"Algeria",
       "id":"dz",
       "code":213
    },
    {
       "name":"American Samoa",
       "id":"as",
       "code":1684
    },
    {
       "name":"Andorra",
       "id":"ad",
       "code":376
    },
    {
       "name":"Angola",
       "id":"ao",
       "code":244
    },
    {
       "name":"Anguilla",
       "id":"ai",
       "code":1264
    },
    {
       "name":"Antigua and Barbuda",
       "id":"ag",
       "code":1268
    },
    {
       "name":"Argentina",
       "id":"ar",
       "code":54
    },
    {
       "name":"Armenia",
       "id":"am",
       "code":374
    },
    {
       "name":"Aruba",
       "id":"aw",
       "code":297
    },
    {
       "name":"Australia",
       "id":"au",
       "code":61
    },
    {
       "name":"Austria",
       "id":"at",
       "code":43
    },
    {
       "name":"Azerbaijan",
       "id":"az",
       "code":994
    },
    {
       "name":"Bahamas",
       "id":"bs",
       "code":1242
    },
    {
       "name":"Bahrain",
       "id":"bh",
       "code":973
    },
    {
       "name":"Bangladesh",
       "id":"bd",
       "code":880
    },
    {
       "name":"Barbados",
       "id":"bb",
       "code":1246
    },
    {
       "name":"Belarus",
       "id":"by",
       "code":375
    },
    {
       "name":"Belgium",
       "id":"be",
       "code":32
    },
    {
       "name":"Belize",
       "id":"bz",
       "code":501
    },
    {
       "name":"Benin",
       "id":"bj",
       "code":229
    },
    {
       "name":"Bermuda",
       "id":"bm",
       "code":1441
    },
    {
       "name":"Bhutan",
       "id":"bt",
       "code":975
    },
    {
       "name":"Bolivia",
       "id":"bo",
       "code":591
    },
    {
       "name":"Bosnia and Herzegovina",
       "id":"ba",
       "code":387
    },
    {
       "name":"Botswana",
       "id":"bw",
       "code":267
    },
    {
       "name":"Brazil",
       "id":"br",
       "code":55
    },
    {
       "name":"British Indian Ocean Territory",
       "id":"io",
       "code":246
    },
    {
       "name":"British Virgin Islands",
       "id":"vg",
       "code":1284
    },
    {
       "name":"Brunei",
       "id":"bn",
       "code":673
    },
    {
       "name":"Bulgaria",
       "id":"bg",
       "code":359
    },
    {
       "name":"Burkina Faso",
       "id":"bf",
       "code":226
    },
    {
       "name":"Burundi",
       "id":"bi",
       "code":257
    },
    {
       "name":"Cambodia",
       "id":"kh",
       "code":855
    },
    {
       "name":"Cameroon",
       "id":"cm",
       "code":237
    },
    {
       "name":"Canada",
       "id":"ca",
       "code":1
    },
    {
       "name":"Cape Verde",
       "id":"cv",
       "code":238
    },
    {
       "name":"Caribbean Netherlands",
       "id":"bq",
       "code":599
    },
    {
       "name":"Cayman Islands",
       "id":"ky",
       "code":1345
    },
    {
       "name":"Central African Republic",
       "id":"cf",
       "code":236
    },
    {
       "name":"Chad",
       "id":"td",
       "code":235
    },
    {
       "name":"Chile",
       "id":"cl",
       "code":56
    },
    {
       "name":"China",
       "id":"cn",
       "code":86
    },
    {
       "name":"Colombia",
       "id":"co",
       "code":57
    },
    {
       "name":"Comoros",
       "id":"km",
       "code":269
    },
    {
       "name":"Congo (DRC)",
       "id":"cd",
       "code":243
    },
    {
       "name":"Congo (Republic)",
       "id":"cg",
       "code":242
    },
    {
       "name":"Cook Islands",
       "id":"ck",
       "code":682
    },
    {
       "name":"Costa Rica",
       "id":"cr",
       "code":506
    },
    {
       "name":"Côte d’Ivoire",
       "id":"ci",
       "code":225
    },
    {
       "name":"Croatia",
       "id":"hr",
       "code":385
    },
    {
       "name":"Cuba",
       "id":"cu",
       "code":53
    },
    {
       "name":"Curaçao",
       "id":"cw",
       "code":599
    },
    {
       "name":"Cyprus",
       "id":"cy",
       "code":357
    },
    {
       "name":"Czech Republic",
       "id":"cz",
       "code":420
    },
    {
       "name":"Denmark",
       "id":"dk",
       "code":45
    },
    {
       "name":"Djibouti",
       "id":"dj",
       "code":253
    },
    {
       "name":"Dominica",
       "id":"dm",
       "code":1767
    },
    {
       "name":"Dominican Republic",
       "id":"do",
       "code":1
    },
    {
       "name":"Ecuador",
       "id":"ec",
       "code":593
    },
    {
       "name":"Egypt",
       "id":"eg",
       "code":20
    },
    {
       "name":"El Salvador",
       "id":"sv",
       "code":503
    },
    {
       "name":"Equatorial Guinea",
       "id":"gq",
       "code":240
    },
    {
       "name":"Eritrea",
       "id":"er",
       "code":291
    },
    {
       "name":"Estonia",
       "id":"ee",
       "code":372
    },
    {
       "name":"Ethiopia",
       "id":"et",
       "code":251
    },
    {
       "name":"Falkland Islands",
       "id":"fk",
       "code":500
    },
    {
       "name":"Faroe Islands",
       "id":"fo",
       "code":298
    },
    {
       "name":"Fiji",
       "id":"fj",
       "code":679
    },
    {
       "name":"Finland",
       "id":"fi",
       "code":358
    },
    {
       "name":"France",
       "id":"fr",
       "code":33
    },
    {
       "name":"French Guiana",
       "id":"gf",
       "code":594
    },
    {
       "name":"French Polynesia",
       "id":"pf",
       "code":689
    },
    {
       "name":"Gabon",
       "id":"ga",
       "code":241
    },
    {
       "name":"Gambia",
       "id":"gm",
       "code":220
    },
    {
       "name":"Georgia",
       "id":"ge",
       "code":995
    },
    {
       "name":"Germany",
       "id":"de",
       "code":49
    },
    {
       "name":"Ghana",
       "id":"gh",
       "code":233
    },
    {
       "name":"Gibraltar",
       "id":"gi",
       "code":350
    },
    {
       "name":"Greece",
       "id":"gr",
       "code":30
    },
    {
       "name":"Greenland",
       "id":"gl",
       "code":299
    },
    {
       "name":"Grenada",
       "id":"gd",
       "code":1473
    },
    {
       "name":"Guadeloupe",
       "id":"gp",
       "code":590
    },
    {
       "name":"Guam",
       "id":"gu",
       "code":1671
    },
    {
       "name":"Guatemala",
       "id":"gt",
       "code":502
    },
    {
       "name":"Guinea",
       "id":"gn",
       "code":224
    },
    {
       "name":"Guinea-Bissau",
       "id":"gw",
       "code":245
    },
    {
       "name":"Guyana",
       "id":"gy",
       "code":592
    },
    {
       "name":"Haiti",
       "id":"ht",
       "code":509
    },
    {
       "name":"Honduras",
       "id":"hn",
       "code":504
    },
    {
       "name":"Hong Kong",
       "id":"hk",
       "code":852
    },
    {
       "name":"Hungary",
       "id":"hu",
       "code":36
    },
    {
       "name":"Iceland",
       "id":"is",
       "code":354
    },
    {
       "name":"India",
       "id":"in",
       "code":91
    },
    {
       "name":"Indonesia",
       "id":"id",
       "code":62
    },
    {
       "name":"Iran",
       "id":"ir",
       "code":98
    },
    {
       "name":"Iraq",
       "id":"iq",
       "code":964
    },
    {
       "name":"Ireland",
       "id":"ie",
       "code":353
    },
    {
       "name":"Israel",
       "id":"il",
       "code":972
    },
    {
       "name":"Italy",
       "id":"it",
       "code":39
    },
    {
       "name":"Jamaica",
       "id":"jm",
       "code":1876
    },
    {
       "name":"Japan",
       "id":"jp",
       "code":81
    },
    {
       "name":"Jordan",
       "id":"jo",
       "code":962
    },
    {
       "name":"Kazakhstan",
       "id":"kz",
       "code":7
    },
    {
       "name":"Kenya",
       "id":"ke",
       "code":254
    },
    {
       "name":"Kiribati",
       "id":"ki",
       "code":686
    },
    {
       "name":"Kuwait",
       "id":"kw",
       "code":965
    },
    {
       "name":"Kyrgyzstan",
       "id":"kg",
       "code":996
    },
    {
       "name":"Laos",
       "id":"la",
       "code":856
    },
    {
       "name":"Latvia",
       "id":"lv",
       "code":371
    },
    {
       "name":"Lebanon",
       "id":"lb",
       "code":961
    },
    {
       "name":"Lesotho",
       "id":"ls",
       "code":266
    },
    {
       "name":"Liberia",
       "id":"lr",
       "code":231
    },
    {
       "name":"Libya",
       "id":"ly",
       "code":218
    },
    {
       "name":"Liechtenstein",
       "id":"li",
       "code":423
    },
    {
       "name":"Lithuania",
       "id":"lt",
       "code":370
    },
    {
       "name":"Luxembourg",
       "id":"lu",
       "code":352
    },
    {
       "name":"Macau",
       "id":"mo",
       "code":853
    },
    {
       "name":"Macedonia",
       "id":"mk",
       "code":389
    },
    {
       "name":"Madagascar",
       "id":"mg",
       "code":261
    },
    {
       "name":"Malawi",
       "id":"mw",
       "code":265
    },
    {
       "name":"Malaysia",
       "id":"my",
       "code":60
    },
    {
       "name":"Maldives",
       "id":"mv",
       "code":960
    },
    {
       "name":"Mali",
       "id":"ml",
       "code":223
    },
    {
       "name":"Malta",
       "id":"mt",
       "code":356
    },
    {
       "name":"Marshall Islands",
       "id":"mh",
       "code":692
    },
    {
       "name":"Martinique",
       "id":"mq",
       "code":596
    },
    {
       "name":"Mauritania",
       "id":"mr",
       "code":222
    },
    {
       "name":"Mauritius",
       "id":"mu",
       "code":230
    },
    {
       "name":"Mexico",
       "id":"mx",
       "code":52
    },
    {
       "name":"Micronesia",
       "id":"fm",
       "code":691
    },
    {
       "name":"Moldova",
       "id":"md",
       "code":373
    },
    {
       "name":"Monaco",
       "id":"mc",
       "code":377
    },
    {
       "name":"Mongolia",
       "id":"mn",
       "code":976
    },
    {
       "name":"Montenegro",
       "id":"me",
       "code":382
    },
    {
       "name":"Montserrat",
       "id":"ms",
       "code":1664
    },
    {
       "name":"Morocco",
       "id":"ma",
       "code":212
    },
    {
       "name":"Mozambique",
       "id":"mz",
       "code":258
    },
    {
       "name":"Myanmar",
       "id":"mm",
       "code":95
    },
    {
       "name":"Namibia",
       "id":"na",
       "code":264
    },
    {
       "name":"Nauru",
       "id":"nr",
       "code":674
    },
    {
       "name":"Nepal",
       "id":"np",
       "code":977
    },
    {
       "name":"Netherlands",
       "id":"nl",
       "code":31
    },
    {
       "name":"New Caledonia",
       "id":"nc",
       "code":687
    },
    {
       "name":"New Zealand",
       "id":"nz",
       "code":64
    },
    {
       "name":"Nicaragua",
       "id":"ni",
       "code":505
    },
    {
       "name":"Niger",
       "id":"ne",
       "code":227
    },
    {
       "name":"Nigeria",
       "id":"ng",
       "code":234
    },
    {
       "name":"Niue",
       "id":"nu",
       "code":683
    },
    {
       "name":"Norfolk Island",
       "id":"nf",
       "code":672
    },
    {
       "name":"North Korea",
       "id":"kp",
       "code":850
    },
    {
       "name":"Northern Mariana Islands",
       "id":"mp",
       "code":1670
    },
    {
       "name":"Norway",
       "id":"no",
       "code":47
    },
    {
       "name":"Oman",
       "id":"om",
       "code":968
    },
    {
       "name":"Pakistan",
       "id":"pk",
       "code":92
    },
    {
       "name":"Palau",
       "id":"pw",
       "code":680
    },
    {
       "name":"Palestine",
       "id":"ps",
       "code":970
    },
    {
       "name":"Panama",
       "id":"pa",
       "code":507
    },
    {
       "name":"Papua New Guinea",
       "id":"pg",
       "code":675
    },
    {
       "name":"Paraguay",
       "id":"py",
       "code":595
    },
    {
       "name":"Peru",
       "id":"pe",
       "code":51
    },
    {
       "name":"Philippines",
       "id":"ph",
       "code":63
    },
    {
       "name":"Poland",
       "id":"pl",
       "code":48
    },
    {
       "name":"Portugal",
       "id":"pt",
       "code":351
    },
    {
       "name":"Puerto Rico",
       "id":"pr",
       "code":1
    },
    {
       "name":"Qatar",
       "id":"qa",
       "code":974
    },
    {
       "name":"Réunion",
       "id":"re",
       "code":262
    },
    {
       "name":"Romania",
       "id":"ro",
       "code":40
    },
    {
       "name":"Russia",
       "id":"ru",
       "code":7
    },
    {
       "name":"Rwanda",
       "id":"rw",
       "code":250
    },
    {
       "name":"Saint Barthélemy",
       "id":"bl",
       "code":590
    },
    {
       "name":"Saint Helena",
       "id":"sh",
       "code":290
    },
    {
       "name":"Saint Kitts and Nevis",
       "id":"kn",
       "code":1869
    },
    {
       "name":"Saint Lucia",
       "id":"lc",
       "code":1758
    },
    {
       "name":"Saint Martin",
       "id":"mf",
       "code":590
    },
    {
       "name":"Saint Pierre and Miquelon",
       "id":"pm",
       "code":508
    },
    {
       "name":"Saint Vincent and the Grenadines",
       "id":"vc",
       "code":1784
    },
    {
       "name":"Samoa",
       "id":"ws",
       "code":685
    },
    {
       "name":"San Marino",
       "id":"sm",
       "code":378
    },
    {
       "name":"São Tomé and Príncipe",
       "id":"st",
       "code":239
    },
    {
       "name":"Saudi Arabia",
       "id":"sa",
       "code":966
    },
    {
       "name":"Senegal",
       "id":"sn",
       "code":221
    },
    {
       "name":"Serbia",
       "id":"rs",
       "code":381
    },
    {
       "name":"Seychelles",
       "id":"sc",
       "code":248
    },
    {
       "name":"Sierra Leone",
       "id":"sl",
       "code":232
    },
    {
       "name":"Singapore",
       "id":"sg",
       "code":65
    },
    {
       "name":"Sint Maarten",
       "id":"sx",
       "code":1721
    },
    {
       "name":"Slovakia",
       "id":"sk",
       "code":421
    },
    {
       "name":"Slovenia",
       "id":"si",
       "code":386
    },
    {
       "name":"Solomon Islands",
       "id":"sb",
       "code":677
    },
    {
       "name":"Somalia",
       "id":"so",
       "code":252
    },
    {
       "name":"South Africa",
       "id":"za",
       "code":27
    },
    {
       "name":"South Korea",
       "id":"kr",
       "code":82
    },
    {
       "name":"South Sudan",
       "id":"ss",
       "code":211
    },
    {
       "name":"Spain",
       "id":"es",
       "code":34
    },
    {
       "name":"Sri Lanka",
       "id":"lk",
       "code":94
    },
    {
       "name":"Sudan",
       "id":"sd",
       "code":249
    },
    {
       "name":"Suriname",
       "id":"sr",
       "code":597
    },
    {
       "name":"Swaziland",
       "id":"sz",
       "code":268
    },
    {
       "name":"Sweden",
       "id":"se",
       "code":46
    },
    {
       "name":"Switzerland",
       "id":"ch",
       "code":41
    },
    {
       "name":"Syria",
       "id":"sy",
       "code":963
    },
    {
       "name":"Taiwan",
       "id":"tw",
       "code":886
    },
    {
       "name":"Tajikistan",
       "id":"tj",
       "code":992
    },
    {
       "name":"Tanzania",
       "id":"tz",
       "code":255
    },
    {
       "name":"Thailand",
       "id":"th",
       "code":66
    },
    {
       "name":"Timor-Leste",
       "id":"tl",
       "code":670
    },
    {
       "name":"Togo",
       "id":"tg",
       "code":228
    },
    {
       "name":"Tokelau",
       "id":"tk",
       "code":690
    },
    {
       "name":"Tonga",
       "id":"to",
       "code":676
    },
    {
       "name":"Trinidad and Tobago",
       "id":"tt",
       "code":1868
    },
    {
       "name":"Tunisia",
       "id":"tn",
       "code":216
    },
    {
       "name":"Turkey",
       "id":"tr",
       "code":90
    },
    {
       "name":"Turkmenistan",
       "id":"tm",
       "code":993
    },
    {
       "name":"Turks and Caicos Islands",
       "id":"tc",
       "code":1649
    },
    {
       "name":"Tuvalu",
       "id":"tv",
       "code":688
    },
    {
       "name":"U.S. Virgin Islands",
       "id":"vi",
       "code":1340
    },
    {
       "name":"Uganda",
       "id":"ug",
       "code":256
    },
    {
       "name":"Ukraine",
       "id":"ua",
       "code":380
    },
    {
       "name":"United Arab Emirates",
       "id":"ae",
       "code":971
    },
    {
       "name":"United Kingdom",
       "id":"gb",
       "code":44
    },
    {
       "name":"United States",
       "id":"us",
       "code":1
    },
    {
       "name":"Uruguay",
       "id":"uy",
       "code":598
    },
    {
       "name":"Uzbekistan",
       "id":"uz",
       "code":998
    },
    {
       "name":"Vanuatu",
       "id":"vu",
       "code":678
    },
    {
       "name":"Vatican City",
       "id":"va",
       "code":39
    },
    {
       "name":"Venezuela",
       "id":"ve",
       "code":58
    },
    {
       "name":"Vietnam",
       "id":"vn",
       "code":84
    },
    {
       "name":"Wallis and Futuna",
       "id":"wf",
       "code":681
    },
    {
       "name":"Yemen",
       "id":"ye",
       "code":967
    },
    {
       "name":"Zambia",
       "id":"zm",
       "code":260
    },
    {
       "name":"Zimbabwe",
       "id":"zw",
       "code":263
    }
 ]