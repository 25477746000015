import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, getDoc, doc, updateDoc } from 'firebase/firestore';

import pageHome from "./pages/home.htm";
import pageIsFoundTag from "./pages/is-found-tag.htm";
import pageIsNewTag from "./pages/is-new-tag.htm";
import pageEmailConfirmed from "./pages/email-confirmed.htm";
import pageTagNotFound from "./pages/tag-not-found.htm";

import mobileCountryCodes from './mobileCountryCodes.json';

const firebaseConfig = {
  apiKey: 'AIzaSyAoUG84I7GkK6GhpaU6ZDbdUH916TP3VwQ',
  authDomain: 'returnplease-e81a8.firebaseapp.com',
  projectId: 'returnplease-e81a8',
  storageBucket: 'returnplease-e81a8.appspot.com',
  messagingSenderId: '154108872459',
  appId: '1:154108872459:web:5bd63aa984cdeb0c42840e'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
let slider, confirmationResult, tagDocRef, ownerPhoneNumber;

function startCountdown() {
  const timerElement = document.getElementById('countDownTimer');
  let timeLeft = 30;

  const countdown = setInterval(() => {
    timeLeft--;
    timerElement.textContent = timeLeft;

    if (timeLeft <= 0) {
      clearInterval(countdown);
      timerElement.textContent = 'Resend Code';
    }
  }, 1000);
}

function showOverlay(type = 'loading', timeOut = 0) {
  const overlay = document.querySelector('.overlay');
  overlay.className = 'overlay';
  overlay.classList.add('show', type);

  if(type == 'success') {
      const countElement = overlay.querySelector('.count');
      let count = 5;
      countElement.textContent = count;
      const countdownInterval = setInterval(() => {
        count--;
        countElement.textContent = count;
        if (count === 1) {
          clearInterval(countdownInterval);
        }
      }, 1000);
  }

  if(timeOut) {
    setTimeout(function() {
      hideOverlay();
    }, timeOut);    
  }
}

function hideOverlay() {
  const overlay = document.querySelector('.overlay');
  overlay.classList.remove('show', 'loading', 'success');
}

function initializeRecaptcha() {
  window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
}

function getFormattedPhoneNumber(inputId, buttonId) {
  const phoneNumber = document.getElementById(inputId).value.replace(/^0+/, '');
  const countryCode = document.getElementById('country-code-dropdown-1').value;
  const formattedValue = '+' + countryCode + phoneNumber.replace(/\s+/g, '');
  document.getElementById(buttonId).disabled = formattedValue.replace(/\s+/g, '').length < 9;
  return formattedValue;
}

async function sendVerificationCode() {
  try {
    initializeRecaptcha();
    const phoneNumber = getFormattedPhoneNumber('phone-number', 'btn-register-number');
    confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
    ownerPhoneNumber = phoneNumber;
    slider.goTo('next');
    window.setTimeout(()=>{
      focusOnFirstCodeInput();
    }, 500);
    startCountdown();
  } catch (error) {
    window.alert('Oeps.. er is iets met je nummer.');
    console.error('SMS not sent', error);
  }
}

async function verifyCode(code) {
  try {
    showOverlay();
    const result = await confirmationResult.confirm(code);
    if (result.user.uid) {
      await updateDoc(tagDocRef, { owner: { uid: result.user.uid, phone: ownerPhoneNumber } });
      showOverlay('success', 5000);
      slider.goTo('next');
    }
  } catch (error) {
    alert("Deze code is onjuist..");
    hideOverlay();
    console.error('Code verification error', error);
  }
}

function getTotalCodeValue() {
  const inputs = document.getElementsByClassName('code-input');
  let totalValue = '';
  for (let i = 0; i < inputs.length; i++) {
    totalValue += inputs[i].value;
  }
  return totalValue;
}

function populateDropdown(data, selectedCountryCode = 31) {
  const dropdowns = document.querySelectorAll('.country-select');
  dropdowns.forEach(dropdown => {
    data.forEach(country => {
      const option = document.createElement('option');
      option.value = country.code;
      if (country.code === selectedCountryCode) {
        option.selected = true;
      }
      option.textContent = `+${country.code}`;
      dropdown.appendChild(option);
    });
  });
}

function focusOnFirstCodeInput() {
  const firstInput = document.querySelector('#code-container .code-input');
  if (firstInput) {
    firstInput.focus();
  }
}

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

async function addAdditionalInfo() {
  showOverlay();
  const emailInput = document.getElementById('input-email');
  const finderMessage = document.getElementById('input-owner-message');
  const email = emailInput.value.trim();
  const message = finderMessage.value.trim();

  if (email.length && !isValidEmail(email)) {
    alert('Het emailadres is ongeldig..');
    emailInput.focus();
    hideOverlay();
    return;
  }

  try {
    if (tagDocRef && (email.length || message.length)) {
      await updateDoc(tagDocRef, { 'owner.email': email, 'owner.message': message });
      if (email.length) {
        const tagDocSnapshot = await getDoc(tagDocRef);
        const token = tagDocSnapshot.data().token;
        const confirmationUrl = `https://api.returnplease.com/tags/${token}/verify-email`;
        
        fetch(confirmationUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error(`Failed to call confirmation URL: ${response.status} ${response.statusText}`);
          }
          console.log(`Confirmation URL called successfully: ${confirmationUrl}`);
        })
        .catch(error => {
          console.error('Error calling confirmation URL:', error);
        });
        console.log(`Confirmation URL called successfully: ${confirmationUrl}`);
      }
      showOverlay('finish');
    }
  } catch (error) {
    console.error('Error updating document:', error);
  }
}

function setupNewTagEventListeners() {
  const registerNumberBtn = document.getElementById('btn-register-number');
  const phoneNumberInput = document.getElementById('phone-number');
  const additionalInfoBtn = document.getElementById('save-additional-info');
  
  registerNumberBtn.addEventListener('click', sendVerificationCode);
  additionalInfoBtn.addEventListener('click', addAdditionalInfo);
  phoneNumberInput.addEventListener('input', () => formatPhoneNumber('phone-number', 'btn-register-number'));
  
  const codeInputs = document.getElementsByClassName('code-input');
  Array.from(codeInputs).forEach(input => setupCodeInputListeners(input, codeInputs));

  populateDropdown(mobileCountryCodes);

  slider = tns({
    container: '#multiStepForm',
    items: 1,
    loop: false,
    touch: false,
    controls: false,
    navContainer: '.stepper',
    responsive: false
  });
}

function setupFoundTagEventListeners() {
  const sendOwnerMessageBtn = document.getElementById('send-owner-message');
  const phoneNumberFinderInput = document.getElementById('phone-number-finder');

  sendOwnerMessageBtn.addEventListener('click', sendOwnerMessage);
  phoneNumberFinderInput.addEventListener('input', () => formatPhoneNumber('phone-number-finder', 'send-owner-message'));

  populateDropdown(mobileCountryCodes);
  window.initMap = initMap;
}

function formatPhoneNumber(inputId, buttonId) {
  const input = document.getElementById(inputId);
  let formattedValue = input.value.replace(/[^0-9]/g, '');
  if (formattedValue.length > 6) {
      formattedValue = formattedValue.replace(/^0/, '');
      formattedValue = formattedValue.substring(0, 1) + ' ' + formattedValue.substring(1);
  }
  document.getElementById(inputId).value = formattedValue;
  document.getElementById(buttonId).disabled = formattedValue.replace(/\s+/g, '').length < 9;
}

function setupCodeInputListeners(input, codeInputs) {
  input.addEventListener('keydown', function (event) {
      if (event.key === 'Backspace' && this.value === '') {
          const previous = this.previousElementSibling;
          if (previous && previous.classList.contains('code-input')) {
              previous.focus();
          }
      }
  });

  input.addEventListener('paste', function (event) {
      event.preventDefault(); // Prevent default paste behavior
      const pastedValue = (event.clipboardData || window.clipboardData).getData('text');
      fillInputs(pastedValue, codeInputs);
      if (pastedValue.length === codeInputs.length) {
          verifyCode(pastedValue); // Verify code if 6 digits are pasted
      }
  });

  input.addEventListener('input', function () {
      const pastedValue = this.value.replace(/[^0-9]/g, '');
      if (pastedValue.length > 1) {
          fillInputs(pastedValue, codeInputs);
          if (pastedValue.length === codeInputs.length) {
              verifyCode(pastedValue); // Verify code if 6 digits are pasted
          }
          return;
      }

      this.value = pastedValue;

      if (this.value.length === 1) {
          const next = this.nextElementSibling;
          if (next && next.classList.contains('code-input')) {
              next.focus();
          } else if (Array.from(codeInputs).indexOf(this) === codeInputs.length - 1) {
              const totalCode = getTotalCodeValue();
              verifyCode(totalCode);
          }
      }
  });
}

function fillInputs(value, codeInputs) {
  const values = value.split('').slice(0, codeInputs.length);
  values.forEach((val, index) => {
      codeInputs[index].value = val;
      if (index < codeInputs.length - 1) {
          codeInputs[index].dispatchEvent(new Event('input'));
          codeInputs[index].dispatchEvent(new Event('change'));
      }
  });
}

document.addEventListener('DOMContentLoaded', async () => {
  const path = window.location.pathname.substring(1);
  if(!path) loadPage(pageHome);
  if(path && path === 'email-confirmed') {
    loadPage(pageEmailConfirmed);
  }
  else if(path) {
    await getTag(path);
  }
});

async function loadPage(name) {
  try {
    const response = await fetch(name);
    if (!response.ok) throw new Error('Failed to fetch HTML fragment');
    const html = await response.text();
    document.getElementById('page').innerHTML = html;
  } catch (error) {
    console.error('Error loading HTML fragment:', error);
    return null;
  }
}

async function getTag(id) {
  try {
    const tagQuery = query(collection(db, 'tags'), where('token', '==', id));
    const querySnapshot = await getDocs(tagQuery);

    for (const doc of querySnapshot.docs) {
      tagDocRef = doc.ref; 
      const data = doc.data();
      if (data.theme) updateTheme(data.theme);
      if (data.owner && data.owner.phone) {
        await loadPage(pageIsFoundTag);
        activateFoundTagMode();
        const findersMessage = document.getElementById('finders-message');
        if (findersMessage && data.owner && data.owner.message) {
          findersMessage.style.display = 'block';
          findersMessage.innerText = data.owner.message;
        }
      } else {
        await loadPage(pageIsNewTag);
        activateNewTagMode();
      }
    }
    if (!tagDocRef) {
      alert('Deze QR-code is niet geldig.');
      loadPage(pageTagNotFound);
    }
  } catch (error) {
    console.error('Error:', error);
  }
}

function initMap() {
  var mapDiv = document.getElementById('map');
  var locationCheckbox = document.getElementById('locationCheckbox');
  locationCheckbox.addEventListener('change', function() {
      if (locationCheckbox.checked) {
          mapDiv.style.display = 'block';
          var map = new google.maps.Map(mapDiv, {
            center: {lat: 0, lng: 0},
            zoom: 15,
            disableDefaultUI: true
        });
          if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(function(position) {
                  var pos = {lat:position.coords.latitude,lng:position.coords.longitude};
                  map.setCenter(pos);
                  var marker = new google.maps.Marker({position:pos,map:map,title:'Jouw locatie'});
              }, function() {handleLocationError(true,map.getCenter());});
          } else {handleLocationError(false,map.getCenter());}
      } else {mapDiv.style.display = 'none';}
  });
}

function handleLocationError(browserHasGeolocation,pos) {
  var infoWindow = new google.maps.InfoWindow({map:map,position:pos,content:browserHasGeolocation?'Error: The Geolocation service failed.':'Error: Your browser doesn\'t support geolocation.'});
}

function updateTheme(theme) {
  if (Object.keys(theme).length) {
    document.documentElement.style.setProperty('--primary-color', theme.primaryColor);
    document.documentElement.style.setProperty('--secondary-color', theme.secondaryColor);
    document.documentElement.style.setProperty('--main-title-font', theme.titleFont);
    document.documentElement.style.setProperty('--logo-src-url', theme.logo);
  }
}

async function sendOwnerMessage() {
  showOverlay();
  const countryCode = document.getElementById('country-code-dropdown-0').value;
  const phoneNumberInput = document.getElementById('phone-number-finder');
  const phoneNumber = phoneNumberInput.value.replace(/[^0-9]/g, '');
  const message = `Hoi, ik heb jouw item gevonden!` && (document.getElementById('input-finder-message').value.trim() || `Neem alsjeblieft contact met me op via +${countryCode}${phoneNumber} om het terug te krijgen.`);
  const locationCheckbox = document.getElementById('locationCheckbox');
  const includeLocation = locationCheckbox.checked;
  
  let whatsappMessage = message;

  try {
      if (tagDocRef) {
          const tagDocSnapshot = await getDoc(tagDocRef);
          const ownerData = tagDocSnapshot.data().owner;
          let lat, lng;
          if (ownerData.phone) {
            let whatsappMessageWithLocation = whatsappMessage;
            if (includeLocation && navigator.geolocation) {
                const position = await new Promise((resolve, reject) => {
                  navigator.geolocation.getCurrentPosition(resolve, reject);
                });
                lat = position.coords.latitude;
                lng = position.coords.longitude;
                const mapsLink = `https://www.google.com/maps?q=${lat},${lng}`;
                const locationMessage = `Dit is mijn huidige locatie: ${mapsLink}`;
                whatsappMessageWithLocation += `\n\n${locationMessage}`;
            }

            const whatsappLink = `https://wa.me/${ownerData.phone}?text=${encodeURIComponent(whatsappMessageWithLocation)}`;

            if(tagDocSnapshot.data().emailVerified && tagDocSnapshot.data().owner.email) {
              const token = tagDocSnapshot.data().token;
              const params = [];
              if (lat && lng) params.push(`geolocation=${lat},${lng}`);
              if (phoneNumber) params.push(`phone=+${countryCode}${phoneNumber}`);
              if (message) params.push(`message=${encodeURIComponent(message)}`);

              const query = params.length > 0 ? `?${params.join('&')}` : '';
              const notificationUrl = `https://api.returnplease.com/tags/${token}/notify${query}`;

              fetch(notificationUrl)
              .then(response => {
                if (!response.ok) {
                  throw new Error(`Failed to call notificationUrl: ${response.status} ${response.statusText}`);
                }
                showOverlay('finish');
                window.location.href = whatsappLink;
                console.log(`notificationUrl called successfully: ${notificationUrl}`);
              })
              .catch(error => {
                console.error('Error calling notificationUrl:', error);
              });
            }
            else {
              showOverlay('finish');
              window.location.href = whatsappLink;
            }
            

          } else {
              alert('Telefoonnummer niet gevonden.');
          }
      } else {
          alert('Tag niet gevonden..');
      }
  } catch (error) {
      hideOverlay();
      console.error('Fout:', error);
  }
}

function activateNewTagMode() {
  setupNewTagEventListeners();
  document.body.classList.add('is-new-tag');
}

function activateFoundTagMode() {
  setupFoundTagEventListeners();
  document.body.classList.add('is-found-tag');
}


